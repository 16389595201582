import React from "react"
import { Link } from "gatsby"
import "./ProjectCardLink.css"

const ProjectCardLink = ({ i, slug, title, mainDescription, listImage }) => {
  let classes = `ProjectCardLink flex flex-col md:flex-row mb-4 mt-4 md:mt-24`
  if (i % 2 !== 0) {
    classes = `${classes} md:flex-row-reverse bg-gray-300 md:bg-white`
  }
  return (
    <Link to={slug} className={classes}>
      <div className="md:w-1/2">
        {listImage && (
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto mt-10 md:mt-0 md:w-2/3"
          />
        )}
      </div>
      <div className="py-8 px-8 md:px-16 md:w-1/2">
        <h2 className="font-semibold text-4xl tracking-wide text-gray-900 leading-none text-center">
          {title}
        </h2>
        <p className="font-light text-xl text-gray-500 my-6">
          {mainDescription}
        </p>
      </div>
    </Link>
  )
}

export default ProjectCardLink
