import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "./Layout"
import ProjectCardLink from "./ProjectCardLink"
import { useIntl } from "react-intl"

export const projectFragment = graphql`
  fragment ProjectFragment on MarkdownRemark {
    id
    fields {
      langKey
      slug
    }
    frontmatter {
      title
      mainDescription
      listImage {
        childImageSharp {
          fixed(width: 1000, quality: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

const ProjectIndexPageTemplate = ({ projects }) => {
  const intl = useIntl()

  return (
    <div className="container mx-auto py-8 md:px-8">
      <Helmet>
        <title>{intl.formatMessage({ id: "nav.caseStudies" })}</title>
      </Helmet>
      {projects.map(({ node: project }, i) => (
        <ProjectCardLink
          i={i}
          key={project.id}
          {...project.fields}
          {...project.frontmatter}
        />
      ))}
    </div>
  )
}

const ProjectIndexPage = ({ location, data }) => {
  const { edges: projects } = data.allMarkdownRemark

  return (
    <Layout location={location}>
      <ProjectIndexPageTemplate projects={projects} />
    </Layout>
  )
}

export default ProjectIndexPage
